import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const SocialMediaSection = styled.div`
  background-color: #333; 
  text-align: center;
  color: white;

  h3 {
    font-size: 1.75rem;
    font-weight: bold;
    color: white;

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }

    @media (max-width: 576px) {
      font-size: 1.25rem;
    }
  }
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  padding: 0.5rem;

  a {
    color: white;
    font-size: 2.5rem;
    transition: color 0.3s ease;

    &:hover {
      color: #007bff;
    }

    @media (max-width: 768px) {
      font-size: 2rem;
    }

    @media (max-width: 576px) {
      font-size: 1.75rem;
    }
  }
`;

const FooterContainer = styled.footer`
  padding: 1rem;
  background-color: #333;
  color: #fff;
  text-align: center;
  justify-content: center;
  position: relative;
  bottom: 0;
  width: 100%;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;

  a {
    color: #007bff;
    text-decoration: none;
    margin-left: 5px;
    &:hover {
      color: #0056b3;
    }
  }

  p {
    margin: 0;
    font-size: 1rem;

    @media (max-width: 768px) {
      font-size: 0.875rem;
    }

    @media (max-width: 576px) {
      font-size: 0.75rem;
    }
  }

  @media (max-width: 768px) {
    padding: 0.75rem;
  }

  @media (max-width: 576px) {
    padding: 0.5rem;
  }
`;

function Footer() {
  return (
    <FooterContainer>
      <SocialMediaSection>
        <h3>Follow Us</h3>
        <SocialIcons>
          <a href="https://www.instagram.com/mehdidc/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a href="https://www.linkedin.com/in/mehdichafai/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
        </SocialIcons>
      </SocialMediaSection>
      <p>
        &copy; 2024 E-Commerce by Mehdi Chafai.
      </p>
    </FooterContainer>
  );
}

export default Footer;