import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '../redux/productSlice';
import ProductCard from '../components/ProductCard';
import styled, { ThemeProvider } from 'styled-components';
import { theme } from '../theme';

const PageWrapper = styled.div`
  padding: ${props => props.theme.spacing.padding};
  background-color: ${props => props.theme.colors.background};
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SearchBar = styled.input`
  padding: 0.75rem 1rem;
  width: 100%;
  max-width: 600px;
  margin-bottom: 2rem;
  margin-top: 5rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;

  &:focus {
    border-color: #007bff;
    outline: none;
    background-color: #fff;
  }
`;

const ProductGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: ${props => props.theme.spacing.margin};
  width: 100%;
  max-width: 1200px;
  justify-content: center; /* Center the products horizontally */
  align-items: center; /* Center the products vertically */
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  @media (max-width: 576px) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
`;

function Products() {
  const dispatch = useDispatch();
  const { products, status, error } = useSelector(state => state.products);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  useEffect(() => {
    setFilteredProducts(
      products.filter(product =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [products, searchTerm]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <ThemeProvider theme={theme}>
      <PageWrapper>
        <SearchBar
          type="text"
          placeholder="Search products..."
          value={searchTerm}
          onChange={handleSearch}
        />
        {status === 'loading' && <p>Loading...</p>}
        {status === 'failed' && <p>{error}</p>}
        <ProductGrid>
          {filteredProducts.map(product => (
            <ProductCard key={product.id} product={product} />
          ))}
        </ProductGrid>
      </PageWrapper>
    </ThemeProvider>
  );
}

export default Products;