import { createSlice } from '@reduxjs/toolkit';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../firebase';

const initialState = {
    products: [],
    status: 'idle',
    error: null,
};

const productSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        getProductsStart(state) {
            state.status = 'loading';
        },
        getProductsSuccess(state, action) {
            state.status = 'succeeded';
            state.products = action.payload;
        },
        getProductsFailure(state, action) {
            state.status = 'failed';
            state.error = action.payload;
        },
    },
});

export const {
    getProductsStart,
    getProductsSuccess,
    getProductsFailure,
} = productSlice.actions;

export const fetchProducts = () => async dispatch => {
    dispatch(getProductsStart());
    try {
        const querySnapshot = await getDocs(collection(firestore, 'Products'));
        const products = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        dispatch(getProductsSuccess(products));
    } catch (error) {
        dispatch(getProductsFailure(error.message));
    }
};

export default productSlice.reducer;