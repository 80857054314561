import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import { useState } from "react";
import styled from "styled-components";
import { useUser } from '../context/UserContext';
import logo from '../Zorak.jpg';

const HeaderContainer = styled.header`
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  z-index: 1000;
  top: 0;
`;

const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 992px) {
    padding: 0.5rem 1rem;
  }
`;

const Brand = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-weight: bold;

  img {
    width: 60px;
    margin-right: 1rem;
  }
`;

const NavMenuLeft = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-grow: 1;  /* Ensure it takes up the remaining space on the left */
  justify-content: flex-start;  /* Align the items to the left */

  @media (max-width: 992px) {
    display: ${({ openedDrawer }) => (openedDrawer ? "flex" : "none")};
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #ffffff;
    padding: 1rem 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
`;

const NavMenuRight = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const NavItem = styled.li`
  margin-left: 2rem;

  @media (max-width: 992px) {
    margin-left: 0;
    margin-bottom: 1rem;
  }

  .nav-link {
    font-size: 1rem;
    color: #555;
    text-decoration: none;
    transition: color 0.3s;

    &:hover {
      color: #007bff;
    }
  }
`;

const CartButton = styled(Link)`
  display: flex;
  align-items: center;
  color: #555;
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: #007bff;
  }

  .badge {
    font-size: 0.75rem;
    font-weight: bold;
    background-color: #333;
    margin-left: 0.5rem;
  }
`;

const BurgerMenuButton = styled.button`
  display: none;
  flex-direction: column;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;

  div {
    width: 25px;
    height: 2px;
    background-color: #333;
    margin: 3px 0;
    transition: all 0.3s ease;
  }

  &.open div:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 5px);
  }
  &.open div:nth-child(2) {
    opacity: 0;
  }
  &.open div:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -5px);
  }

  @media (max-width: 992px) {
    display: flex;
  }
`;

function Header() {
  const [openedDrawer, setOpenedDrawer] = useState(false);
  const { user, isAdmin } = useUser();

  function toggleDrawer() {
    setOpenedDrawer(!openedDrawer);
  }

  function closeNav() {
    setOpenedDrawer(false);
  }

  return (
    <HeaderContainer>
      <Navbar>
        <Brand to="/" onClick={closeNav}>
          <img src={logo} alt="Logo" />
        </Brand>
        <NavMenuLeft openedDrawer={openedDrawer}>
          <NavItem>
            <Link to="/about" className="nav-link" replace onClick={closeNav}>
              About
            </Link>
          </NavItem>
          <NavItem>
            <Link to="/products" className="nav-link" replace onClick={closeNav}>
              Products
            </Link>
          </NavItem>
          <NavItem>
            <Link to="/contact" className="nav-link" replace onClick={closeNav}>
              Contact
            </Link>
          </NavItem>
        </NavMenuLeft>
        <NavMenuRight>
          {user && isAdmin && (
            <NavItem>
              <Link to="/admin" className="nav-link" replace onClick={closeNav}>
                Admin
              </Link>
            </NavItem>
          )}
          {user && (
            <NavItem>
              <Link to="/profile" className="nav-link" replace onClick={closeNav}>
                Profile
              </Link>
            </NavItem>
          )}
          {!user && (
            <>
              <NavItem>
                <Link to="/signin" className="nav-link">
                  Sign In
                </Link>
              </NavItem>
              <NavItem>
                <Link to="/signup" className="nav-link">
                  Sign Up
                </Link>
              </NavItem>
            </>
          )}
          <NavItem>
            <CartButton to="/cart" onClick={closeNav}>
              <FontAwesomeIcon icon={faShoppingCart} />
            </CartButton>
          </NavItem>
        </NavMenuRight>
        <BurgerMenuButton
          className={openedDrawer ? "open" : ""}
          onClick={toggleDrawer}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              toggleDrawer();
            }
          }}
          tabIndex="0"
          aria-label="Toggle navigation menu"
        >
          <div></div>
          <div></div>
          <div></div>
        </BurgerMenuButton>
      </Navbar>
    </HeaderContainer>
  );
}

export default Header;