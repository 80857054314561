import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body, html {
    margin: 0;
    padding: 0;
    background-color: #f0f0f0;
  }
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 2rem;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    padding: 2rem 1.5rem;
  }

  @media (max-width: 992px) {
    padding: 1.5rem 1rem;
  }

  @media (max-width: 768px) {
    padding: 1.5rem 0.5rem;
  }

  @media (max-width: 576px) {
    padding: 1rem 0.5rem;
  }
`;

const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  max-width: 800px;
  width: 80%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 1200px) {
    padding: 1.5rem;
  }

  @media (max-width: 992px) {
    padding: 1.5rem;
  }

  @media (max-width: 768px) {
    padding: 1.5rem;
    box-shadow: none;
  }

  @media (max-width: 576px) {
    padding: 1rem;
    box-shadow: none;
  }
`;

const Title = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  text-align: center;
  color: #333;

  @media (max-width: 1200px) {
    font-size: 2.2rem;
  }

  @media (max-width: 992px) {
    font-size: 2rem;
  }

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }

  @media (max-width: 576px) {
    font-size: 1.5rem;
  }
`;

const Description = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  color: #555;
  text-align: center;

  @media (max-width: 1200px) {
    font-size: 1.1rem;
  }

  @media (max-width: 992px) {
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    font-size: 0.95rem;
  }

  @media (max-width: 576px) {
    font-size: 0.9rem;
  }
`;

function About() {
  return (
    <>
      <GlobalStyle />
      <PageContainer>
        <AboutContainer>
          <Title>About Me</Title>
          <Description>
            I created this site for a personal project. It showcases my skills in web development and design.
            The site is built using modern technologies like React and styled-components to create a responsive
            and visually appealing user experience.
          </Description>
        </AboutContainer>
      </PageContainer>
    </>
  );
}

export default About;