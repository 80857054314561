import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { removeFromCart, updateQuantity } from '../redux/cartSlice';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const PageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 1rem;
  background-color: #f0f0f0;
  box-sizing: border-box;
`;

const CartContainer = styled.div`
  padding: 2rem;
  max-width: 800px;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const CartItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #ddd;
`;

const CartItemInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const CartItemTitle = styled.h3`
  margin: 0;
  font-size: 1.25rem;
`;

const CartItemPrice = styled.p`
  margin: 0.5rem 0;
`;

const QuantityInput = styled.input`
  width: 50px;
  padding: 0.25rem;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const RemoveButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: #ff4d4d;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #cc0000;
  }
`;

const CheckoutButton = styled.button`
  padding: 1rem 2rem;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 2rem;
  transition: background-color 0.3s;
  &:hover {
    background-color: #218838;
  }
`;

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cart = useSelector(state => state.cart);

  const handleRemoveFromCart = (productId) => {
    dispatch(removeFromCart({ id: productId }));
    toast.error('Product removed from cart', {
      autoClose: 2000
    });
  };

  const handleUpdateQuantity = (productId, quantity) => {
    dispatch(updateQuantity({ id: productId, quantity: Number(quantity) }));
  };

  const handleCheckout = () => {
    navigate('/checkout');
  };

  return (
    <PageWrapper>
      <CartContainer>
        <h2>Your Cart</h2>
        {cart.length === 0 ? (
          <p>Your cart is empty.</p>
        ) : (
          <>
            {cart.map(item => (
              <CartItem key={item.id}>
                <CartItemInfo>
                  <CartItemTitle>{item.name}</CartItemTitle>
                  <CartItemPrice>{item.price} $</CartItemPrice>
                  <QuantityInput
                    type="number"
                    value={item.quantity}
                    onChange={(e) => handleUpdateQuantity(item.id, e.target.value)}
                    min="1"
                  />
                </CartItemInfo>
                <RemoveButton onClick={() => handleRemoveFromCart(item.id)}>Remove</RemoveButton>
              </CartItem>
            ))}
            <CheckoutButton onClick={handleCheckout}>Checkout</CheckoutButton>
          </>
        )}
      </CartContainer>
    </PageWrapper>
  );
};

export default Cart;