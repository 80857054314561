import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addToCart } from '../redux/cartSlice';
import { doc, getDoc, collection, addDoc, query, orderBy, onSnapshot, deleteDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUser } from '../context/UserContext';

const DetailContainer = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 2rem auto;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const Image = styled.img`
  width: 100%;
  max-width: 500px;
  margin: 0 auto 2rem;
  display: block;
  border-radius: 12px;
`;

const Info = styled.div`
  margin-top: 2rem;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #333;
`;

const Price = styled.p`
  font-size: 1.8rem;
  color: #007bff;
  margin-bottom: 1rem;
  font-weight: bold;
`;

const Description = styled.p`
  margin-bottom: 2rem;
  color: #666;
`;

const AddToCartButton = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const ReviewSection = styled.div`
  margin-top: 3rem;
`;

const ReviewTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #333;
`;

const ReviewInstruction = styled.p`
  font-size: 1rem;
  margin-bottom: 1rem;
  color: #666;
`;

const ReviewForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

const Textarea = styled.textarea`
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  resize: vertical;
  min-height: 150px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const SubmitButton = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s;

  &:hover {
    background-color: #218838;
  }

  &:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
`;

const ReviewsList = styled.div`
  margin-top: 2rem;
`;

const ReviewItem = styled.div`
  border-bottom: 1px solid #ddd;
  padding: 1rem 0;
`;

const ReviewUserName = styled.p`
  font-weight: bold;
  color: #333;
`;

const ReviewText = styled.p`
  margin: 0.5rem 0;
  color: #666;
`;

const DeleteButton = styled.button`
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 0.9rem;
  margin-top: 1rem;

  &:hover {
    background-color: #c82333;
  }
`;

function ProductDetail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { user, isAdmin } = useUser();
  const [product, setProduct] = useState(null);
  const [review, setReview] = useState('');
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    fetchProduct(id, setProduct);
    const unsubscribe = subscribeToReviews(id, setReviews);
    return () => unsubscribe();
  }, [id]);

  const handleAddToCart = () => {
    dispatch(addToCart(product));
    toast.success(`${product.name} has been added to the cart!`);
  };

  const handleReviewSubmit = async (e) => {
    e.preventDefault();
    if (!review.trim()) {
      toast.error('Review cannot be empty.');
      return;
    }

    try {
      await submitReview(id, review, user);
      setReview('');
      toast.success('Review submitted!');
    } catch (error) {
      toast.error('Failed to submit review.');
    }
  };

  const handleDeleteReview = async (reviewId) => {
    if (!isAdmin) {
      toast.error('Only admins can delete reviews.');
      return;
    }

    try {
      await deleteReview(id, reviewId);
      toast.success('Review deleted!');
    } catch (error) {
      toast.error('Failed to delete review.');
    }
  };

  if (!product) return <p>Loading...</p>;

  return (
    <DetailContainer>
      <Image src={product.image} alt={product.name} />
      <Info>
        <Title>{product.name}</Title>
        <Price>${product.price}</Price>
        <Description>{product.description}</Description>
        <AddToCartButton onClick={handleAddToCart}>Add to Cart</AddToCartButton>
      </Info>
      <ReviewSection>
        <ReviewTitle>Leave a Review</ReviewTitle>
        <ReviewInstruction>We value your feedback! Please leave a review below.</ReviewInstruction>
        <ReviewForm onSubmit={handleReviewSubmit}>
          <Textarea
            value={review}
            onChange={(e) => setReview(e.target.value)}
            placeholder="Write your review here..."
            rows="5"
          />
          <SubmitButton type="submit" disabled={!review.trim()}>Submit Review</SubmitButton>
        </ReviewForm>
      </ReviewSection>
      <ReviewsList>
        {reviews.length > 0 ? (
          reviews.map((rev) => (
            <ReviewItem key={rev.id}>
              <ReviewUserName>{rev.userName}</ReviewUserName>
              <ReviewText>{rev.reviewText}</ReviewText>
              {isAdmin && (
                <DeleteButton onClick={() => handleDeleteReview(rev.id)}>Delete</DeleteButton>
              )}
            </ReviewItem>
          ))
        ) : (
          <p>No reviews yet.</p>
        )}
      </ReviewsList>
    </DetailContainer>
  );
}

async function fetchProduct(id, setProduct) {
  const productRef = doc(firestore, 'Products', id);
  const productSnap = await getDoc(productRef);

  if (productSnap.exists()) {
    setProduct({ id: productSnap.id, ...productSnap.data() });
  } else {
    console.error('No such product!');
  }
}

function subscribeToReviews(id, setReviews) {
  const reviewsRef = collection(firestore, 'Products', id, 'reviews');
  const q = query(reviewsRef, orderBy('timestamp', 'desc'));

  const unsubscribe = onSnapshot(q, (snapshot) => {
    const fetchedReviews = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setReviews(fetchedReviews);
  });

  return unsubscribe;
}

async function submitReview(productId, reviewText, user) {
  const reviewsRef = collection(firestore, 'Products', productId, 'reviews');
  await addDoc(reviewsRef, {
    userId: user.uid,
    userName: user.displayName,
    reviewText,
    timestamp: new Date(),
  });
}

async function deleteReview(productId, reviewId) {
  const reviewRef = doc(firestore, 'Products', productId, 'reviews', reviewId);
  await deleteDoc(reviewRef);
}

export default ProductDetail;