// theme.js
export const theme = {
    colors: {
        primary: '#6200ea', // Primary color
        secondary: '#03dac6', // Secondary color
        background: '#f5f5f5', // Light background
        text: '#333', // Dark text
        cardBackground: '#fff', // White for cards
        border: '#e0e0e0', // Light border color
    },
    typography: {
        fontFamily: "'Roboto', sans-serif",
        heading: '1.75rem',
        subheading: '1.25rem',
        body: '1rem',
    },
    spacing: {
        padding: '1rem',
        margin: '1rem',
    },
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
};