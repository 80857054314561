import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, updateEmail, updatePassword, deleteUser, signOut, reauthenticateWithCredential, EmailAuthProvider, sendEmailVerification } from 'firebase/auth';
import styled from 'styled-components';
import { useUser } from '../context/UserContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';

const PageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 1rem;
  background-color: #f0f0f0;
  box-sizing: border-box;
`;

const ProfileContainer = styled.div`
  padding: 2rem;
  max-width: 800px;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 1.5rem;
    max-width: 90%;
  }

  @media (max-width: 480px) {
    padding: 1rem;
    max-width: 95%;
  }
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center; /* Center align all buttons */
  gap: 1rem; /* Add a consistent gap between the buttons */
  margin-bottom: 2rem;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */

  @media (max-width: 768px) {
    gap: 0.5rem; /* Adjust gap for smaller screens */
  }
`;

const TabButton = styled.button`
  padding: 1rem 2rem;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s;
  text-align: center; /* Ensure text is centered */

  &:hover {
    background-color: #0056b3;
  }

  &.active {
    background-color: #0056b3;
  }

  @media (max-width: 768px) {
    padding: 0.75rem 1.5rem;
  }

  @media (max-width: 480px) {
    padding: 0.5rem 1rem;
    flex: 1; /* Allow buttons to stretch and fill available space */
  }
`;

const TabContent = styled.div`
  padding: 1rem 2rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;

  @media (max-width: 768px) {
    padding: 1rem;
  }

  @media (max-width: 480px) {
    padding: 0.75rem;
  }
`;

const Input = styled.input`
  display: block;
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;

  @media (max-width: 768px) {
    padding: 0.4rem;
  }

  @media (max-width: 480px) {
    padding: 0.3rem;
  }
`;

const Button = styled.button`
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #218838;
  }

  &:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    padding: 0.6rem 1.2rem;
  }

  @media (max-width: 480px) {
    padding: 0.5rem 1rem;
  }
`;

const Profile = () => {
  const { user } = useUser();
  const [activeTab, setActiveTab] = useState('profile');

  return (
    <PageContainer>
      <ProfileContainer>
        <h2>Profile Page</h2>
        <TabContainer>
          <TabButton className={activeTab === 'profile' ? 'active' : ''} onClick={() => setActiveTab('profile')}>Profile Info</TabButton>
          <TabButton className={activeTab === 'updateEmail' ? 'active' : ''} onClick={() => setActiveTab('updateEmail')}>Update Email</TabButton>
          <TabButton className={activeTab === 'updatePassword' ? 'active' : ''} onClick={() => setActiveTab('updatePassword')}>Update Password</TabButton>
          <TabButton className={activeTab === 'signOut' ? 'active' : ''} onClick={() => setActiveTab('signOut')}>Sign Out</TabButton>
          <TabButton className={activeTab === 'deleteAccount' ? 'active' : ''} onClick={() => setActiveTab('deleteAccount')}>Delete Account</TabButton>
        </TabContainer>
        {activeTab === 'profile' && <ProfileInfo user={user} />}
        {activeTab === 'updateEmail' && <UpdateEmail />}
        {activeTab === 'updatePassword' && <UpdatePassword />}
        {activeTab === 'signOut' && <SignOut />}
        {activeTab === 'deleteAccount' && <DeleteAccount />}
        <ToastContainer />
      </ProfileContainer>
    </PageContainer>
  );
};

const ProfileInfo = ({ user }) => {
  const handleVerifyEmail = async () => {
    try {
      await sendEmailVerification(user);
      toast.success('Verification email sent. Please check your inbox.');
    } catch (error) {
      toast.error(`Error sending verification email: ${error.message}`);
    }
  };

  return (
    <TabContent>
      <h3>Profile Information</h3>
      <p><strong>Verified:</strong> {user.emailVerified ? 'Yes' : 'No'}</p>
      <p><strong>Email:</strong> {user.email}</p>
      <p><strong>Name:</strong> {user.displayName}</p>
      {!user.emailVerified && (
        <Button onClick={handleVerifyEmail}>Verify Email</Button>
      )}
    </TabContent>
  );
};

ProfileInfo.propTypes = {
  user: PropTypes.shape({
    emailVerified: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string,
  }).isRequired,
};


const UpdateEmail = () => {
  const navigate = useNavigate();
  const [newEmail, setNewEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const auth = getAuth();
  const { user } = useUser();

  const handleUpdateEmail = async () => {
    try {
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      await reauthenticateWithCredential(user, credential);
      await updateEmail(user, newEmail);
      toast.success('Email updated successfully, signing out...');
      setTimeout(() => {
        signOut(auth);
        navigate('/signin');
      }, 1000);
    }
    catch (error) {
      toast.error(`Error updating email: ${error.message}`);
    }
  };

  return (
    <TabContent>
      <h3>Update Email</h3>
      <Input
        type="email"
        value={newEmail}
        onChange={(e) => setNewEmail(e.target.value)}
        placeholder="Enter new email"
      />
      <Input
        type="password"
        value={currentPassword}
        onChange={(e) => setCurrentPassword(e.target.value)}
        placeholder="Enter current password"
      />
      <Button onClick={handleUpdateEmail}>Update Email</Button>
    </TabContent>
  );
};

const UpdatePassword = () => {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const auth = getAuth();
  const { user } = useUser();

  const handleUpdatePassword = async () => {
    try {
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, newPassword);
      toast.success('Password updated successfully, signing out...');
      setTimeout(() => {
        signOut(auth);
        navigate('/signin');
      }, 1000);
    } catch (error) {
      toast.error(`Error updating password: ${error.message}`);
    }
  };

  return (
    <TabContent>
      <h3>Update Password</h3>
      <Input
        type="password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        placeholder="Enter new password"
      />
      <Input
        type="password"
        value={currentPassword}
        onChange={(e) => setCurrentPassword(e.target.value)}
        placeholder="Enter current password"
      />
      <Button onClick={handleUpdatePassword}>Update Password</Button>
    </TabContent>
  );
};

const SignOut = () => {
  const auth = getAuth();
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      toast.success('Signed out successfully, redirecting to sign in page...');
      setTimeout(() => {
        navigate('/signin');
      }, 1000);
    } catch (error) {
      toast.error(`Error signing out: ${error.message}`);
    }
  };

  return (
    <TabContent>
      <h3>Sign Out</h3>
      <Button onClick={handleSignOut}>Sign Out</Button>
    </TabContent>
  );
};

const DeleteAccount = () => {
  const { user } = useUser();
  const [currentPassword, setCurrentPassword] = useState('');
  const navigate = useNavigate();

  const handleDeleteAccount = async () => {
    try {
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      await reauthenticateWithCredential(user, credential);
      await deleteUser(user);
      toast.success('Account deleted successfully, redirecting to sign up page...');
      setTimeout(() => {
        navigate('/signup');
      }, 1000);
    } catch (error) {
      toast.error(`Error deleting account: ${error.message}`);
    }
  };

  return (
    <TabContent>
      <h3>Delete Account</h3>
      <Input
        type="password"
        value={currentPassword}
        onChange={(e) => setCurrentPassword(e.target.value)}
        placeholder="Enter current password"
      />
      <Button onClick={handleDeleteAccount}>Delete Account</Button>
    </TabContent>
  );
};

export default Profile;