import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '../redux/productSlice';
import ProductCard from '../components/ProductCard';
import styled, { ThemeProvider } from 'styled-components';
import { theme } from '../theme';
import bannerImage from '../banner.jpg';

const PageWrapper = styled.div`
  background-color: ${props => props.theme.colors.background};
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #2b2b2b;
`;

const Banner = styled.div`
  width: 100%;
  height: 400px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bannerImage});
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.6);

  h1 {
    font-size: 3.5rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }

  @media (max-width: 768px) {
    height: 250px;
    h1 {
      font-size: 2.5rem;
    }
  }
`;

const ContentWrapper = styled.div`
  padding: 3rem 1.5rem;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h2`
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: 2.5rem;
  font-weight: 700;
  color: #2b2b2b;
  text-align: center;
  margin-bottom: 2.5rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 576px) {
    font-size: 1.75rem;
  }
`;

const ProductGrid = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: nowrap;
  overflow-x: auto;
  width: 100%;
  max-width: 1200px;
  padding: 1rem 0;

  & > div {
    min-width: 280px;
  }

  @media (max-width: 768px) {
    gap: 1rem;
  }

  @media (max-width: 576px) {
    gap: 0.5rem;
  }
`;

function Shop() {
  const dispatch = useDispatch();
  const { products, status, error } = useSelector(state => state.products);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  // Trier les produits par date et sélectionner le plus récent
  const latestProduct = products
    .slice() // Créer une copie des produits
    .sort((a, b) => new Date(b.date) - new Date(a.date))[0]; // Trier par date et prendre le premier produit

  return (
    <ThemeProvider theme={theme}>
      <PageWrapper>
        <Banner />
        <ContentWrapper>
          <Title>New Arrival</Title>
          {status === 'loading' && <p>Loading...</p>}
          {status === 'failed' && <p>{error}</p>}
          <ProductGrid>
            {latestProduct && <ProductCard key={latestProduct.id} product={latestProduct} />}
          </ProductGrid>
        </ContentWrapper>
      </PageWrapper>
    </ThemeProvider>
  );
}

export default Shop;