import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '../context/UserContext';

const ProtectedRoute = ({ element: Component, ...rest }) => {
    const { user, isAdmin } = useUser();

    if (user === null) {
        // User state is still being determined, you can show a loading indicator here
        return <div>Loading...</div>;
    }

    if (!user) {
        // User is not authenticated
        return <Navigate to="/signin" />;
    }

    if (!isAdmin) {
        // User is authenticated but not an admin
        return <Navigate to="/" />;
    }

    // User is authenticated and an admin
    return <Component {...rest} />;
};

export default ProtectedRoute;