import React, { useEffect, useState } from 'react';
import { collection, getDocs, addDoc, updateDoc, doc, getDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { firestore, storage } from '../firebase';
import styled from 'styled-components';
import { useUser } from '../context/UserContext';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 6rem 2rem 2rem; /* Added top padding to avoid overlap with fixed header */
  background-color: #f0f0f0;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    padding: 6rem 1.5rem 2rem; /* Adjusted top padding for smaller screens */
  }

  @media (max-width: 992px) {
    padding: 6rem 1rem 2rem;
  }

  @media (max-width: 768px) {
    padding: 6rem 0.5rem 2rem;
  }

  @media (max-width: 576px) {
    padding: 6rem 0.5rem 2rem;
  }
`;

const AdminContainer = styled.div`
  max-width: 800px;
  background-color: #fff;
  border-radius: 8px;
  width: 80%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 2rem;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 2rem;
  flex-wrap: wrap;
`;

const TabButton = styled.button`
  padding: 1rem 2rem;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s;
  margin: 0.5rem;

  &:hover {
    background-color: #0056b3;
  }

  &.active {
    background-color: #0056b3;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

const Input = styled.input`
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
`;

const Button = styled.button`
  padding: 0.75rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const FileInput = styled.input`
  display: none;
`;

const CustomFileLabel = styled.label`
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 1rem;
  text-align: center;

  &:hover {
    background-color: #0056b3;
  }
`;

const ListContainer = styled.div`
  padding: 1rem 0;
`;

const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #ddd;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const UserInfo = styled.span`
  margin-bottom: 0.5rem;

  @media (min-width: 601px) {
    margin-bottom: 0;
  }
`;

const ProductImage = styled.img`
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
  margin-right: 1rem;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
`;

const AdminPanel = () => {
    const { user } = useUser();
    const [isAdmin, setIsAdmin] = useState(false);
    const [products, setProducts] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [productName, setProductName] = useState('');
    const [productDescription, setProductDescription] = useState('');
    const [productPrice, setProductPrice] = useState('');
    const [productImage, setProductImage] = useState(null);
    const [userEmail, setUserEmail] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [activeTab, setActiveTab] = useState('products');

    useEffect(() => {
        if (!user) {
            console.error('User not logged in.');
            return;
        }

        const checkAdminStatus = async () => {
            const userDoc = await getDoc(doc(firestore, 'Users', user.uid));
            if (userDoc.exists() && userDoc.data().isAdmin) {
                setIsAdmin(true);
            } else {
                console.error('Access Denied. Only admins can access this page.');
            }
        };

        checkAdminStatus();
    }, [user]);

    useEffect(() => {
        if (!isAdmin) return;

        const fetchProducts = async () => {
            try {
                const querySnapshot = await getDocs(collection(firestore, 'Products'));
                const productsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setProducts(productsList);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        const fetchUsers = async () => {
            try {
                const querySnapshot = await getDocs(collection(firestore, 'Users'));
                const usersList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setUsers(usersList);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchProducts();
        fetchUsers();
    }, [isAdmin]);

    const handleAddOrUpdateProduct = async (e) => {
        e.preventDefault();
        try {
            let imageURL = '';
            if (productImage) {
                const imageRef = ref(storage, `products/${productImage.name}`);
                const snapshot = await uploadBytes(imageRef, productImage);
                imageURL = await getDownloadURL(snapshot.ref);
            }

            if (selectedProduct) {
                const productRef = doc(firestore, 'Products', selectedProduct.id);
                await updateDoc(productRef, {
                    name: productName,
                    description: productDescription,
                    price: parseFloat(productPrice),
                    image: imageURL || selectedProduct.image,
                });
            } else {
                await addDoc(collection(firestore, 'Products'), {
                    name: productName,
                    description: productDescription,
                    price: parseFloat(productPrice),
                    image: imageURL,
                });
            }

            setProductName('');
            setProductDescription('');
            setProductPrice('');
            setProductImage(null);
            setSelectedProduct(null);
            const querySnapshot = await getDocs(collection(firestore, 'Products'));
            const productsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setProducts(productsList);
        } catch (error) {
            console.error('Error adding/updating product:', error);
        }
    };

    const handleSelectProduct = (product) => {
        setSelectedProduct(product);
        setProductName(product.name);
        setProductDescription(product.description);
        setProductPrice(product.price);
        setProductImage(null); // Reset the file input
    };

    const handleUpdateUser = async (userId, isAdminStatus) => {
        try {
            if (!isAdmin) {
                throw new Error('Only admins can update user roles.');
            }
            const userRef = doc(firestore, 'Users', userId);
            await updateDoc(userRef, { isAdmin: isAdminStatus });
            const querySnapshot = await getDocs(collection(firestore, 'Users'));
            const usersList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setUsers(usersList);
        } catch (error) {
            console.error('Error updating user:', error);
        }
    };

    const handleSelectUser = (user) => {
        setSelectedUser(user);
        setUserEmail(user.email);
    };

    const handleSaveUserChanges = async () => {
        if (!selectedUser) return;
        try {
            const auth = getAuth();
            const userRef = doc(firestore, 'Users', selectedUser.id);
            await updateDoc(userRef, { email: userEmail, isAdmin: selectedUser.isAdmin });
            if (userEmail !== selectedUser.email) {
                setUserEmail(auth.currentUser, userEmail);
            }
            if (userPassword) {
                setUserPassword(auth.currentUser, userPassword);
            }
            const querySnapshot = await getDocs(collection(firestore, 'Users'));
            const usersList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setUsers(usersList);
            setSelectedUser(null);
            setUserEmail('');
            setUserPassword('');
        } catch (error) {
            console.error('Error updating user:', error);
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file?.type.startsWith('image/')) {
            setProductImage(file);
        } else {
            alert('Please select a valid image file');
        }
    };

    if (!user || !isAdmin) {
        return <div>Access Denied. Only admins can access this page.</div>;
    }

    return (
        <PageWrapper>
            <AdminContainer>
                <h2>Admin Panel</h2>
                <TabContainer>
                    <TabButton className={activeTab === 'products' ? 'active' : ''} onClick={() => setActiveTab('products')}>Products</TabButton>
                    <TabButton className={activeTab === 'users' ? 'active' : ''} onClick={() => setActiveTab('users')}>Users</TabButton>
                </TabContainer>

                {activeTab === 'products' && (
                    <>
                        <h3>{selectedProduct ? 'Edit Product' : 'Add Product'}</h3>
                        <Form onSubmit={handleAddOrUpdateProduct}>
                            <Input
                                type="text"
                                value={productName}
                                onChange={(e) => setProductName(e.target.value)}
                                placeholder="Product Name"
                                required
                            />
                            <Input
                                type="text"
                                value={productDescription}
                                onChange={(e) => setProductDescription(e.target.value)}
                                placeholder="Product Description"
                                required
                            />
                            <Input
                                type="number"
                                value={productPrice}
                                onChange={(e) => setProductPrice(e.target.value)}
                                placeholder="Product Price"
                                required
                            />
                            <FileInput
                                type="file"
                                id="productImage"
                                onChange={handleFileChange}
                                accept="image/*"
                            />
                            <CustomFileLabel htmlFor="productImage">Choose Image</CustomFileLabel>
                            <Button type="submit">{selectedProduct ? 'Update Product' : 'Add Product'}</Button>
                        </Form>

                        <ListContainer>
                            <h3>Products</h3>
                            <ul>
                                {products.map(product => (
                                    <ListItem key={product.id}>
                                        {product.image && <ProductImage src={product.image} alt={product.name} />}
                                        <UserInfo>{product.name}</UserInfo>
                                        <UserInfo>{product.price} $</UserInfo>
                                        <Button onClick={() => handleSelectProduct(product)}>Edit</Button>
                                    </ListItem>
                                ))}
                            </ul>
                        </ListContainer>
                    </>
                )}

                {activeTab === 'users' && (
                    <>
                        <ListContainer>
                            <h3>Users</h3>
                            <ul>
                                {users.map(user => (
                                    <ListItem key={user.id}>
                                        <UserInfo>{user.email}</UserInfo>
                                        <UserInfo>Admin: {user.isAdmin ? 'Yes' : 'No'}</UserInfo>
                                        <Button onClick={() => handleUpdateUser(user.id, !user.isAdmin)}>
                                            {user.isAdmin ? 'Revoke Admin' : 'Make Admin'}
                                        </Button>
                                        <Button onClick={() => handleSelectUser(user)}>Edit</Button>
                                    </ListItem>
                                ))}
                            </ul>
                        </ListContainer>

                        {selectedUser && (
                            <Form onSubmit={(e) => { e.preventDefault(); handleSaveUserChanges(); }}>
                                <h3>Edit User</h3>
                                <Input
                                    type="email"
                                    value={userEmail}
                                    onChange={(e) => setUserEmail(e.target.value)}
                                    placeholder="User Email"
                                    required
                                />
                                <Input
                                    type="password"
                                    value={userPassword}
                                    onChange={(e) => setUserPassword(e.target.value)}
                                    placeholder="New Password"
                                />
                                <ButtonGroup>
                                    <Button type="submit">Save Changes</Button>
                                    <Button type="button" onClick={() => setSelectedUser(null)}>Cancel</Button>
                                </ButtonGroup>
                            </Form>
                        )}
                    </>
                )}
            </AdminContainer>
        </PageWrapper>
    );
};

export default AdminPanel;