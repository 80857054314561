import React, { useRef } from 'react';
import styled from 'styled-components';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';

const PageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 1rem;
  background-color: #f8f8f8;
  box-sizing: border-box;
`;

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  max-width: 500px;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 1.5rem;
    max-width: 90%;
  }

  @media (max-width: 480px) {
    padding: 1rem;
    max-width: 95%;
  }
`;

const Title = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  color: #333;
  text-align: center;
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }

  @media (max-width: 480px) {
    font-size: 1.25rem;
  }
`;

const IntroText = styled.p`
  font-size: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  color: #666;

  @media (max-width: 768px) {
    font-size: 0.875rem;
  }

  @media (max-width: 480px) {
    font-size: 0.75rem;
  }
`;

const Form = styled.form`
  width: 100%;
`;

const FormControl = styled.div`
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    margin-bottom: 0.75rem;
  }
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #333;
  font-size: 0.9rem;

  @media (max-width: 768px) {
    margin-bottom: 0.4rem;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 0.7rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.9rem;
  background-color: #f9f9f9;

  @media (max-width: 768px) {
    padding: 0.6rem;
    font-size: 0.875rem;
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: 0.7rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.9rem;
  resize: vertical;
  background-color: #f9f9f9;

  @media (max-width: 768px) {
    padding: 0.6rem;
    font-size: 0.875rem;
  }
`;

const Button = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 768px) {
    padding: 0.6rem 1.2rem;
    font-size: 0.9rem;
  }

  @media (max-width: 480px) {
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
  }
`;

function Contact() {
  const formRef = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_0xqmsnj',
        'template_whjekgg',
        formRef.current,
        'rP6xdFVXyBSTiLStt'
      )
      .then(
        (result) => {
          toast.success('Email sent successfully!');
          formRef.current.reset();
        },
        (error) => {
          toast.error('Failed to send email.');
        }
      );
  };

  return (
    <PageWrapper>
      <ContactContainer>
        <IntroText>Have any questions? Reach out to us!</IntroText>
        <Title>Contact Us</Title>
        <Form ref={formRef} onSubmit={sendEmail}>
          <FormControl>
            <Label htmlFor="name">Name</Label>
            <Input type="text" id="from_name" name="from_name" required />
          </FormControl>
          <FormControl>
            <Label htmlFor="email">Email</Label>
            <Input type="email" id="from_email" name="from_email" required />
          </FormControl>
          <FormControl>
            <Label htmlFor="message">Message</Label>
            <Textarea id="message" name="message" required />
          </FormControl>
          <Button type="submit">Send</Button>
        </Form>
      </ContactContainer>
    </PageWrapper>
  );
}

export default Contact;
