import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';

const SignInContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 2rem;
  background-color: #f8f8f8;
  box-sizing: border-box;
`;

const FormContainer = styled.div`
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
`;

const Title = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  text-align: center;
  color: #333;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Input = styled.input`
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  background-color: #f9f9f9;
`;

const Button = styled.button`
  padding: 0.75rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

const StyledLink = styled(Link)`
  font-size: 0.9rem;
  color: #007bff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

function SignIn() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const auth = getAuth();
    const firestore = getFirestore();

    const handleSignIn = async (email, password) => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            const userDoc = await getDoc(doc(firestore, 'Users', user.uid));
            if (userDoc.exists()) {
                const userData = userDoc.data();
                if (userData.isAdmin) {
                    toast.success('Welcome back, Admin! Redirecting to admin dashboard...');
                    setTimeout(() => navigate('/admin'), 1000);
                } else {
                    toast.success('Successfully signed in! Redirecting to home page...');
                    setTimeout(() => navigate('/'), 1000);
                }
            } else {
                toast.error('No user data found! Please contact support.');
            }
        } catch (error) {
            toast.error(`Error signing in: ${error.message}`);
        }
    };

    return (
        <SignInContainer>
            <FormContainer>
                <Title>Sign In</Title>
                <Form
                    onSubmit={event => {
                        event.preventDefault();
                        handleSignIn(email, password);
                    }}
                >
                    <Input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={event => setEmail(event.target.value)}
                    />
                    <Input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={event => setPassword(event.target.value)}
                    />
                    <Button type="submit">Sign In</Button>
                </Form>
                <LinksContainer>
                    <StyledLink to="/signup">Sign Up</StyledLink>
                    <StyledLink to="/reset-password">Forgot Password?</StyledLink>
                </LinksContainer>
                <ToastContainer />
            </FormContainer>
        </SignInContainer>
    );
}

export default SignIn;