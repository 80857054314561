import React from 'react';
import styled from 'styled-components';

const CheckoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 2rem;
  background-color: #f0f0f0;
`;

const Message = styled.h1`
  color: #333;
  font-size: 2rem;
  text-align: center;
`;

const Checkout = () => {
    return (
        <CheckoutContainer>
            <Message>There's no need to go further, this is just a site to showcase my skills.</Message>
        </CheckoutContainer>
    );
};

export default Checkout;